import React, { useEffect } from "react";

import styles from "./Calendar.module.css";
import "./Calendar.css";

import { Scheduler, useScheduler } from "@aldabil/react-scheduler";
import { EventActions, ProcessedEvent } from "@aldabil/react-scheduler/types";
import {
  CalendarEvent,
  VideoPlatform,
  VideoPlatformToLabelMap,
} from "../../calendarEvents";

type CalendarProps = {
  events: Map<string, CalendarEvent>;
  handleConfirm: (
    event: ProcessedEvent,
    action: EventActions
  ) => Promise<ProcessedEvent>;
  handeDelete: (deletedId: string) => Promise<string>;
  handleUpdate: (
    droppedOn: Date,
    updatedEvent: ProcessedEvent,
    originalEvent: ProcessedEvent
  ) => Promise<ProcessedEvent>;
  isLoading: boolean;
};

const ConvertCalendarEventToProcessedEvent = (
  calEvent: CalendarEvent
): ProcessedEvent => {
  return {
    event_id: calEvent.title,
    title: calEvent.title,
    start: calEvent.startTime,
    end: new Date(
      calEvent.startTime.getTime() + calEvent.durationMinutes * (1000 * 60)
    ),
    video_platform: calEvent.videoPlatform,
    attendees: calEvent.attendees.join("\n"),
  };
};

export const ConvertProcessedEventToCalendarEvent = (
  event: ProcessedEvent
): CalendarEvent => {
  return {
    title: event.title,
    attendees: event.attendees.split("\n"),
    startTime: event.start,
    durationMinutes:
      (event.end.getTime() - event.start.getTime()) / (1000 * 60),
    videoPlatform: event.video_platform,
  };
};

const Calendar: React.FC<CalendarProps> = (props) => {
  const { setEvents, triggerLoading } = useScheduler();
  useEffect(() => {
    const events = Array.from(props.events).map(([key, value]) => {
      return ConvertCalendarEventToProcessedEvent(value);
    });
    setEvents(events);
    // eslint-disable-next-line
  }, [props.events]);

  useEffect(() => {
    triggerLoading(props.isLoading);
    // eslint-disable-next-line
  }, [props.isLoading]);

  const videoPlatformOptions = Object.values(VideoPlatform).map(
    (videoPlatform, index) => {
      return {
        id: index,
        text: VideoPlatformToLabelMap[videoPlatform as VideoPlatform],
        value: videoPlatform,
      };
    }
  );
  return (
    <div className={styles.root}>
      <Scheduler
        onEventDrop={props.handleUpdate}
        onConfirm={props.handleConfirm}
        onDelete={props.handeDelete}
        view="week"
        day={{
          startHour: 0,
          endHour: 23,
          step: 60,
          navigation: true,
        }}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 6,
          startHour: 0,
          endHour: 23,
          step: 60,
          navigation: true,
          disableGoToDay: false,
        }}
        fields={[
          {
            name: "video_platform",
            type: "select",
            options: videoPlatformOptions,
            config: {
              label: "Video Platform",
              required: true,
              errMsg: "Plz Select Video Platform",
            },
          },
          {
            name: "attendees",
            type: "input",
            default: "",
            config: { label: "Attendees", multiline: true, rows: 4 },
          },
        ]}
        viewerExtraComponent={(fields, event) => {
          return (
            <div>
              <p>
                Video Platform:{" "}
                {VideoPlatformToLabelMap[event.video_platform as VideoPlatform]}
              </p>
              <div>
                Attendees:
                <ul>
                  {event.attendees.split("\n").map((attendee: string) => {
                    return <li key={attendee}> {attendee} </li>;
                  })}
                </ul>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Calendar;

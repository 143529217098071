import { askModelForDslMeeting } from "./lib/gpt";

export enum VideoPlatform {
  Zoom = "ZOOM",
  Meet = "MEET",
  None = "NONE",
}

export const VideoPlatformToLabelMap: { [key in VideoPlatform]: string } = {
  [VideoPlatform.Zoom]: "Zoom",
  [VideoPlatform.Meet]: "Meet",
  [VideoPlatform.None]: "None",
};

export type CalendarEvent = {
  title: string;
  attendees: Array<string>;
  startTime: Date;
  durationMinutes: number;
  videoPlatform: VideoPlatform;
};

// Type Guard to detect if string VideoPlatform
function isVideoPlatform(
  videoPlatform: string
): videoPlatform is VideoPlatform {
  return Object.values(VideoPlatform).includes(videoPlatform as VideoPlatform);
}

export const GetEventToReturnToCalendar = async (
  userInput: string,
  name: string
): Promise<CalendarEvent> => {
  const chatGBTEventObject = await askModelForDslMeeting(userInput, name);
  if (
    chatGBTEventObject == null ||
    !chatGBTEventObject.TITLE ||
    !chatGBTEventObject.ATTENDEES ||
    !chatGBTEventObject.DURATION ||
    !chatGBTEventObject.VIDEO ||
    !chatGBTEventObject.START_TIME ||
    chatGBTEventObject.TITLE === "UNKNOWN"
  ) {
    throw Error("Failed to create a calendar event");
  }
  if (chatGBTEventObject.START_TIME === "UNKNOWN") {
    throw Error(
      "Failed to create a calendar event since a start time could not be determined"
    );
  }
  const calendarEvent: CalendarEvent = {
    title: chatGBTEventObject.TITLE,
    attendees:
      chatGBTEventObject.ATTENDEES === "UNKNOWN"
        ? []
        : chatGBTEventObject.ATTENDEES.split(", "),
    startTime: new Date(chatGBTEventObject.START_TIME),
    // If duration is missing for meeting set it to 15 min
    durationMinutes:
      chatGBTEventObject.DURATION === "UNKNOWN"
        ? 15
        : Number(chatGBTEventObject.DURATION),
    // If the video platform is UNKNOWN or missing from the list of supported platfroms set it to none.
    videoPlatform: isVideoPlatform(chatGBTEventObject.VIDEO)
      ? chatGBTEventObject.VIDEO
      : VideoPlatform.None,
  };
  return calendarEvent;
};

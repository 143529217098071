import React from "react";
import Modal from "react-modal";
import { Button, TextField } from "@mui/material";

import styles from "./NewUserModal.module.css";
import { modalStyles } from "../../modalUtils";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

type NewEventModalProps = {
  onSubmit: (userInput: string) => void;
  nameIsSet: boolean;
};

const NewUserModal: React.FC<NewEventModalProps> = (props) => {
  const [userInput, setUserInput] = React.useState("");

  const handleUserInput = (e: any) => {
    setUserInput(e.target.value);
  };

  const submitValue = () => {
    props.onSubmit(userInput);
  };

  return (
    <div>
      <Modal isOpen={!props.nameIsSet} style={modalStyles}>
        <h2>Welcome to LLM Calendar</h2>
        <div>Please enter your name:</div>
        <div className={styles.inputContainer}>
          <TextField
            onChange={handleUserInput}
            label="Name:"
            variant="filled"
            fullWidth
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                submitValue();
              }
            }}
          />
        </div>
        <div className={styles.submitButtonContainer}>
          <Button disabled={!userInput} onClick={submitValue}>
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default NewUserModal;

export const setLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    return null;
  }
};

export const getFromLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

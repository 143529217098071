import React from "react";
import Modal from "react-modal";
import { Button, TextField } from "@mui/material";

import styles from "./NewEventModal.module.css";
import { modalStyles } from "../../modalUtils";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

type NewEventModalProps = {
  onSubmit: (userInput: string) => void;
};

const NewEventModal: React.FC<NewEventModalProps> = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [userInput, setUserInput] = React.useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleUserInput = (e: any) => {
    setUserInput(e.target.value);
  };

  const submitValue = () => {
    closeModal();
    setUserInput("");
    props.onSubmit(userInput);
  };

  return (
    <div className={styles.root}>
      <Button onClick={openModal}>Add new event</Button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <h2>Create new meeting</h2>
        <div>
          Describe the meeting you want to create. You can specify date, time,
          duration, participants and type of meeting room software
        </div>
        <form>
          <div className={styles.inputContainer}>
            <TextField
              onChange={handleUserInput}
              label="Event Description:"
              multiline
              rows={4}
              variant="filled"
              fullWidth
            />
          </div>
          <div className={styles.submitButtonContainer}>
            <Button onClick={submitValue}>Submit</Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default NewEventModal;

import React, { useCallback, useEffect, useRef, useState } from "react";
import Calendar, {
  ConvertProcessedEventToCalendarEvent,
} from "./Components/Calendar/Calendar";
import { Alert } from "@mui/material";
import NewEventModal from "./Components/NewEventModal/NewEventModal";

import styles from "./App.module.css";
import NewUserModal from "./Components/NewUserModal/NewUserModal";
import { EventActions, ProcessedEvent } from "@aldabil/react-scheduler/types";
import { CalendarEvent, GetEventToReturnToCalendar } from "./calendarEvents";
import { getFromLocalStorage, setLocalStorage } from "./localStorageUtils";

function App() {
  const isMounted = useRef(false);
  const [eventToUpdate, setEventToUpdate] = useState<ProcessedEvent>();
  const [eventToDelete, setEventToDelete] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();
  const [calendarIsLoading, setCalendarIsLoading] = useState<boolean>(false);
  const [events, setEventsUnsafe] = useState<Map<string, CalendarEvent>>(
    new Map()
  );
  const [name, setName] = useState<string>("");
  useEffect(() => {
    const eventsFromStorage = getFromLocalStorage("events");
    if (eventsFromStorage) {
      const parsedEventsFromStorage = new Map(JSON.parse(eventsFromStorage));
      parsedEventsFromStorage.forEach((parsedEvent: any) => {
        parsedEvent.startTime = new Date(parsedEvent.startTime);
      });
      setEventsUnsafe(parsedEventsFromStorage as Map<string, CalendarEvent>);
    }
    const nameFromStorage = getFromLocalStorage("name");
    if (nameFromStorage) {
      setName(nameFromStorage);
    }
  }, []);
  useEffect(() => {
    if (isMounted.current) {
      setLocalStorage("name", name);
      setLocalStorage("events", JSON.stringify(Array.from(events.entries())));
    } else {
      isMounted.current = true;
    }
  }, [events, name]);

  const updateEventsMap = useCallback(
    (eventId: string, event: CalendarEvent) => {
      setEventsUnsafe(new Map(events.set(eventId, event)));
    },
    [events]
  );

  const deleteEvent = (eventId: string) => {
    setEventsUnsafe((prev) => {
      const newState = new Map(prev);
      newState.delete(eventId);
      return newState;
    });
  };

  const replaceEvent = (oldEventId: string, newEvent: CalendarEvent) => {
    setEventsUnsafe((prev) => {
      const newState = new Map(prev);
      newState.delete(oldEventId);
      newState.set(newEvent.title, newEvent);
      return newState;
    });
  };

  // @aldabil/react-scheduler/types does not correctly handle updated callbacks being passed in.
  // This is a workaround to make sure our state is correct when handling a callback
  useEffect(() => {
    if (eventToUpdate) {
      setErrorMessage("");
      setSuccessMessage("");
      const calEvent = ConvertProcessedEventToCalendarEvent(eventToUpdate);
      // We're using titles as event IDs for simplicity
      if (eventToUpdate.event_id !== eventToUpdate.title) {
        // Check if event title is already used
        if (events.has(eventToUpdate.title)) {
          setErrorMessage(
            "Cannot have two events with the same title. Title not updated"
          );
          calEvent.title = eventToUpdate.event_id as string;
          updateEventsMap(calEvent.title, calEvent);
          // debugger
        } else {
          replaceEvent(eventToUpdate.event_id as string, calEvent);
        }
      } else {
        updateEventsMap(calEvent.title, calEvent);
      }
    }
    setEventToUpdate(undefined);
  }, [eventToUpdate, events, updateEventsMap]);

  // @aldabil/react-scheduler/types does not correctly handle updated callbacks being passed in.
  // This is a workaround to make sure our state is correct when handling a callback
  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
    if (eventToDelete) {
      deleteEvent(eventToDelete);
    }
  }, [eventToDelete]);

  const AddEventToCalendar = async (userInput: string) => {
    setErrorMessage("");
    setSuccessMessage("");
    setCalendarIsLoading(true);
    try {
      const calEvent = await GetEventToReturnToCalendar(userInput, name);
      if (events.has(calEvent.title)) {
        setErrorMessage(
          `Failed to create event "${calEvent.title}" since it shares a tile with an existing meeting`
        );
        return;
      }
      updateEventsMap(calEvent.title, calEvent);
      setSuccessMessage(
        `Added meeting ${calEvent.title} at ${calEvent.startTime}`
      );
    } catch (e: any) {
      setErrorMessage(e.message);
    } finally {
      setCalendarIsLoading(false);
    }
  };

  const handleConfirm = useCallback(
    async (
      event: ProcessedEvent,
      _action: EventActions
    ): Promise<ProcessedEvent> => {
      setEventToUpdate(event);
      return event;
    },
    []
  );

  const handleDelete = useCallback(
    async (deletedId: string): Promise<string> => {
      setEventToDelete(deletedId);
      return deletedId as string;
    },
    []
  );

  const handleUpdated = useCallback(
    async (
      _droppedOn: Date,
      updatedEvent: ProcessedEvent,
      _originalEvent: ProcessedEvent
    ): Promise<ProcessedEvent> => {
      setEventToUpdate(updatedEvent);
      return updatedEvent;
    },
    []
  );

  return (
    // id is set here for the purpose of react modal to enable accessibility features (https://reactcommunity.org/react-modal/accessibility/)
    <div id="root">
      <div className={styles.alertContainer}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
      </div>
      <header className={styles.heading}>LLM Calendar</header>
      <div className={styles.calendarContainer}>
        <Calendar
          events={events}
          handleConfirm={handleConfirm}
          handeDelete={handleDelete}
          handleUpdate={handleUpdated}
          isLoading={calendarIsLoading || !isMounted.current}
        />
      </div>
      <div className={styles.addNewEventModalContainer}>
        <NewEventModal onSubmit={AddEventToCalendar} />
      </div>
      <div>
        {isMounted.current && (
          <NewUserModal onSubmit={setName} nameIsSet={!!name} />
        )}
      </div>
    </div>
  );
}

export default App;
